import React from 'react'

const AyatEncours = (props) => {

    const { ayat, range, learn } = props


    return (

        <section className="ayah_active" >

            {learn && range && <p className='range'>Ayat {range[0]} à {range[1]} </p>}


            <h2>

                {ayat}
            </h2>


        </section>
    );
}

export default React.memo(AyatEncours);