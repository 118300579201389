import React, { useState } from 'react'
import { Range } from 'rc-slider';
import Qurant from '../globaljs/quran'
// We can just import Slider or Range to reduce bundle size
// import Slider from 'rc-slider/lib/Slider';
// import Range from 'rc-slider/lib/Range';
import 'rc-slider/assets/index.css';

function Modal(props) {
    const { sourate, setReapt } = props


    const [minAyat, setminAyat] = useState(1)
    const [maxAyat, setmaxAyat] = useState(Qurant.surah.detail(sourate).ayahs)
    const [repeat, setrepeat] = useState(5)
    const max = Qurant.surah.detail(sourate).ayahs
    function handleRange(e) {

        setminAyat(e[0])
        setmaxAyat(e[1])

        setReapt(e, repeat)
        //repatMode(e)
    }

    const isMax = (minAyat, maxAyat) => {
        return minAyat != maxAyat ? 'au ' + maxAyat : ''
    }

    const handleSelection = (e) => {
        setrepeat(e.currentTarget.options[e.currentTarget.selectedIndex].value)
    }
    return (
        <>
            <section className="modal">
                <span className='configuration'>
                    <select name="fois" id="repeat-select" className='select-box' onChange={(e) => handleSelection(e)}>
                        <option value="5">5X </option>
                        <option value="10">10X </option>
                        <option value="20">20X</option>
                        <option value="30">30X</option>
                        <option value="100">100X</option>

                    </select>

                    <span className='textconfig'>Répéter <b>{repeat}</b> fois <br />  le Verset <b>{minAyat}</b> <b>{isMax(minAyat, maxAyat)}</b></span>
                    <Range defaultValue={[1, max]} min={1} max={max} onChange={(e) => handleRange(e)} />
                </span>
            </section>

        </>
    )
}

export default Modal
