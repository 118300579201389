import React from 'react';

const Head = (props) => {
    const changementSourate = () => {
        cliqued()
    }
    const { titre, cliqued } = props
    return (

        <header onClick={changementSourate}>

            <h1> {titre}  </h1>


        </header>

    );
}

export default React.memo(Head);