import React, { useState, useRef, useEffect } from 'react';
import play from '../img/play.png'
import pause from '../img/pause.png'
import Quran from '../globaljs/quran'


let i = 0;
let previosayat
let repeat = 10000000000000;
let learnMode = false
let loopRange = []

function threeDigits(num) {
    var temp = '000' + num;
    return temp.substr(temp.length - 3);
}


const Audio = (props) => {
    const audioPlayer = useRef();
    const { temps, indexAyat, etat, finSourate, rangeSet, learn, num } = props

    const refTemps = useRef(temps)

    //const srcAudio = "https://audio.coran-islam.com/arabe/" + threeDigits(num) + ".mp3"
    let srcAudio
    num == 67 ? srcAudio = "https://audio.coran-islam.com/arabe/" + threeDigits(num) + ".mp3"
        : srcAudio = "https://audio.coran-islam.com/arabe/" + threeDigits(55) + ".mp3"

    if (rangeSet != null) {

        learnMode = learn


        loopRange = rangeSet;

    }


    if (indexAyat) {

        if (indexAyat != previosayat) {
            previosayat = indexAyat
            i = indexAyat


            audioPlayer.current.currentTime = temps[indexAyat]
        }


    }



    let [lecture, setLecture] = useState(true)
    useEffect(() => {
        setLecture(true)
        i = 0

    }, [num])


    refTemps.current = temps

    const HandleClick = () => {
        // console.log('============== audio ==============')


        setLecture(!lecture)

        audioPlayer.current.onended = function () {

            if (!learnMode) {
                finSourate();
                i = 0
                // setLecture(!lecture)
                setLecture(true)
                // console.log(lecture)
            }

        }



        const player = () => {


            if (audioPlayer.current.currentTime > refTemps.current[i]) {





                if (learnMode) {
                    //i = loopRange[0]

                    // console.log(loopRange)
                    if (repeat >= 0) {


                        if (i >= loopRange[1]) {

                            // console.log('je suis ', i)
                            i = loopRange[0] - 1
                            audioPlayer.current.currentTime = refTemps.current[i]

                            repeat--

                        }

                    }


                }


                if (i < Quran.surah.detail(num).ayahs) {
                    etat(i)
                    ++i
                }


                // console.log('je suis plus bas ', i)
            }

        }

        if (lecture === true) {
            audioPlayer.current.play();



            audioPlayer.current.addEventListener("timeupdate", player)
        } else {
            audioPlayer.current.pause()
            audioPlayer.current.removeEventListener("timeupdate", player)
        }

    }


    return (

        <>
            <span className='audio' onClick={HandleClick} >
                <img className="bouton" src={lecture ? play : pause} width="28px" height="28px" alt='lecture' />
            </span>
            <audio ref={audioPlayer} src={srcAudio} preload='auto' ></audio>

        </>
    );
}

export default React.memo(Audio);