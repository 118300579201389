import React, { useState, useRef, useEffect, createRef } from 'react'
import Ayat from './ayat'
import cancel from '../img/left-arrow.png'


let limit = []
let cleanArray = []
let colored = []
let ayatColor = {}
const Sourate = (props) => {
    const menu = useRef()


    const { dataSourate, numSourate, rangeSet } = props


    let ayats_json = localStorage.getItem("c" + numSourate);
    let localAyat = JSON.parse(ayats_json);
    useEffect(() => {
        // dataSourate = dataSourate
        ayats_json = localStorage.getItem("c" + numSourate);
        localAyat = JSON.parse(ayats_json);
        menu.current.className = 'MenuM'
        limit = []

        if (!localAyat) { localAyat = {} }
        else { ayatColor = localAyat }

    }, [numSourate])

    function handleClick(event) {


        if (event.target.id !== '') {




            limit.push(event.target.id)
            rangeSet(limit)
            cleanArray.push(event.target.id)


            for (let i = Math.min(...cleanArray); i <= Math.max(...cleanArray); i++) {

                document.getElementById(i).classList.remove('borded')
            }



            for (let i = Math.min(...limit); i <= Math.max(...limit); i++) {

                document.getElementById(i).classList.add("borded");
            }
            colored = limit
            if (limit.length == 1) { menu.current.classList.add('animation'); }
            if (limit.length == 2) { limit = [] }


            document.getElementById(event.target.id).classList.add("borded");


            props.ayatSelect(event.target.id)
        }

    }



    function handleClickColor(event) {
        if (event.target.className === 'back') {
            menu.current.classList.remove('animation');
            limit = []
            for (let i = Math.min(...cleanArray); i <= Math.max(...cleanArray); i++) {

                document.getElementById(i).classList.remove('borded')
            }
            cleanArray = []


        }

        else {


            for (let i = Math.min(...colored); i <= Math.max(...colored); i++) {
                document.getElementById(i).className = 'borded'
                document.getElementById(i).classList.add(event.target.className);
                ayatColor[i] = event.target.className
                localStorage.setItem('c' + numSourate, JSON.stringify(ayatColor))
                limit = []

            }
            // console.log(ayatColor)


        }
    }


    return (
        <>
            <div ref={menu} className="MenuM" onClick={(event) => handleClickColor(event)}>

                <ul className="surligneur">

                    <li className="bleu"></li>
                    <li className="vert"></li>
                    <li className="darckblue"></li>
                    <li className="rose"></li>
                    <li className="orange"></li>
                    <li className="white"></li>
                    <li className="back"><img className='back' src={cancel} /></li>

                </ul>


            </div>

            <section className="apercu_ayat" onClick={(event) => handleClick(event)} >





                {




                    dataSourate && dataSourate.map(
                        (ayat, key) => {

                            return < Ayat ayat={ayat.verse} css={localAyat != null ? localAyat[key] : ''} key={key} id={key}
                                idAyat={ayat.ayah} />


                        })}
                <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br />
            </section>
        </>


    );
}

export default Sourate