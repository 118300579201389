import React, { useState, useEffect, useCallback, useReducer, useRef } from 'react';
import './App.css';
import Nom from './components/head'
import Audio from './components/audio'
import Sourate from './components/sourate'
import Ayat from './components/ayat-encours'
import Config from './components/reglages'
import axios from 'axios';
import bismillah from './img/Bismillah.png'
import Modal from './components/modal'
import Quran from './globaljs/quran'
import PouchDB from 'pouchdb-browser'
import Temps from './globaljs/temps'
import { Handle } from 'rc-slider';
const db = new PouchDB('Coran');



const localSourate = () => {
  if (localStorage.getItem('num')) { return localStorage.getItem('num') }
  else { return 67 }
}



const getSourate = async (num) => {


  const key = 'https://api.globalquran.com/surah/' + num + '/quran-simple?key=523a7c9582e02ff891311e9b7b0be9cb'
  const dataSourate = await axios.get(key)

  const sourate = dataSourate



  return sourate
}

function App() {

  const [temps, setTemps] = useState([6.222078, 12.328412, 17.888534, 22.143189, 26.98999, 32.829317, 38.901413, 98])

  let changeSourate = []
  const [sourate, setSourate] = useState([])
  const [name, setName] = useState([])
  const [ayatEncours, setAyatEncours] = useState()
  const [indexAyat, setindexAyat] = useState()
  const [num, setnum] = useState(localSourate())
  const state = useRef(sourate)



  const [rangeLimit, setrangeLimit] = useState()
  const [learn, setlearn] = useState(false)
  //const [isLoading, setIsLoading] = useState(false)


  useEffect(() => {



    let nom = Quran.surah.detail(num).arabic_name
    setName(nom)

    setTemps(Temps[num])
    //console.log(temps)
    setAyatEncours(<img src={bismillah} width='150px' />)

    db.get('s' + num).then(function (doc) {
      // handle doc
      console.log('from database')
      setSourate(doc.data)
      state.current = doc.data



      //console.log('database', doc.data)

    }).catch(function (err) {


      if (err.status === 404) {


        const getData = async () => {
          const dataSourate = await getSourate(num)

          //console.log("coll", dataSourate.data.quran['quran-simple'])
          let sourateConvert = []
          dataSourate && Object.entries(dataSourate.data.quran['quran-simple']).map((ayat) => {
            sourateConvert.push(ayat[1])

          })



          db.put({
            _id: 's' + num,
            title: Quran.surah.detail(num).arabic_name,
            data: sourateConvert
          }).then(function (response) {
            // handle response
            //console.log('super')
          }).catch(function (err) {
            //console.log(err);
          });

          setSourate(sourateConvert)
          state.current = sourateConvert


          //console.log('use effect ==================')
        }

        getData()

      };
    });

    // 
  }, [num]);



  function getEtat(ayantEncours) {
    //console.log('je log ==================', sourate[ayantEncours].verse)
    // console.log('dou ca vien', sourate)
    // console.log('dispatch ', state)
    console.log(ayantEncours)
    setAyatEncours(state.current[ayantEncours].verse)

  }





  const ayatSelect = useCallback(
    (ayat) => {
      //console.log(ayat)
      setindexAyat(ayat)
    },
    [sourate],
  )



  function HandleChangeSourate() {

    num == 67 ? localStorage.setItem('num', 55) : localStorage.setItem('num', 67)
    num == 67 ? setnum(55) : setnum(67)
    setSourate([])
    // document.location.reload(true);
  }
  function handelEndSourate() {
    setAyatEncours(<img src={bismillah} width='150px' />)
  }

  function rangeSet(range) {

    // let first = parseInt(range[0]) + 1
    // let second = parseInt(range[1]) + 1
    let first = Math.min(...range) + 1
    let second = Math.max(...range) + 1
    setrangeLimit([first, second])


    if (range.length === 2) {
      setlearn(true)




    } else {
      setlearn(false)
    }
  }
  return (
    <>
      {/* <Modal sourate={num} setReapt={setReapt} /> */}

      {/* <Config /> */}
      {/* {console.log('encours', changeSourate)} */}

      <Ayat ayat={ayatEncours} range={rangeLimit} learn={learn} />

      <Sourate dataSourate={sourate} ayatSelect={ayatSelect} numSourate={num} rangeSet={rangeSet} />
      {/* <Audio lecture={lecture} setLecture={setLecture} /> */}
      <Audio num={num} learn={learn} etat={getEtat} temps={temps} finSourate={handelEndSourate} indexAyat={indexAyat} rangeSet={rangeLimit} />
      <Nom titre={name} cliqued={HandleChangeSourate} />
    </>

  );
}

export default App;
