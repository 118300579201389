import React from 'react'
const ayat = (props) => {
    const { ayat, id, idAyat, css } = props





    return (
        <>

            <p id={id} className={css}><span className="num_verset"  >{idAyat}</span>{ayat}</p>

        </>
    );
}

export default React.memo(ayat);